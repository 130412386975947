:root {
  --border-radius: 0.35rem;
}

@import "bootstrap-custom.css";

@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url("../public/fonts/Inter-Light.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("../public/fonts/Inter-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("../public/fonts/Inter-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("../public/fonts/Inter-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("../public/fonts/Inter-Bold.ttf") format("truetype");
  font-display: swap;
}


@font-face {
  font-family: Noto Sans Myanmar UI;
  font-weight: 300;
  src: url("../public/fonts/NotoSansMyanmarUI-Light.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: Noto Sans Myanmar UI;
  font-weight: 400;
  src: url("../public/fonts/NotoSansMyanmarUI-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Noto Sans Myanmar UI;
  font-weight: 500;
  src: url("../public/fonts/NotoSansMyanmarUI-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Noto Sans Myanmar UI;
  font-weight: 600;
  src: url("../public/fonts/NotoSansMyanmarUI-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Noto Sans Myanmar UI;
  font-weight: 700;
  src: url("../public/fonts/NotoSansMyanmarUI-Bold.ttf") format("truetype");
  font-display: swap;
}

html,
body {
  margin: 0;
  height: 100%;
  background-color: #fff;
  font-family: Inter, Noto Sans Myanmar UI, -apple-system, BlinkMacSystemFont, Roboto,
    Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif !important;
}

#root {
  height: 100%;
}

svg {
  display: block;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* width */
.scrollbar-custom::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
.scrollbar-custom::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 3px;
}

/* Handle */
.scrollbar-custom::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

/* Handle on hover */
.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(1, 0, 0); /* Black fallback color */
  background-color: rgb(1, 0, 0, 0.5); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
}

.form-switch {
  display: flex;
  align-items: flex-end;
}

.form-switch .form-check-input {
  padding: 0.65rem 1.2rem !important;
  margin-right: 0.5rem;
}

.custom-nav-link,
.custom-nav-link svg {
  color: rgb(156, 163, 175);
  text-decoration: none;
}

.custom-nav-link:hover {
  color: white;
  background: rgba(220, 220, 220, 0.1);
  text-decoration: none;
}

.custom-nav-link:hover svg {
  color: white;
  text-decoration: none;
}

.custom-nav-link:focus {
  color: white;
  background-color: var(--bs-primary);
  text-decoration: none;
}

.custom-nav-link:focus svg {
  color: white;
  text-decoration: none;
}

.custom-nav-link.active {
  color: white;
  background-color: var(--bs-primary);
  text-decoration: none;
}

.custom-nav-link.active svg {
  color: white;
  text-decoration: none;
}

/* Tinymce */
.tox-pop .tox-pop__dialog .tox-toolbar {
  padding: 0 !important;
}
